<!--
This component exports the maps data to a CSV file.
a prop name maps comes as is:
"maps": [
          {
            "islandCode": "1760-0152-1306",
            "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
            "author": "PrettyBoyyAM",
            "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
            "playerCount": 5576,
            "tags": ["deathrun","parkour","just for fun","difficulty: easy"]
        },
        ...
]
implement a button that exports to csv, using a blob and saveAs from file-saver
-->

<template>
    <div>
        <b-table striped hover :items="maps" :fields="fields">
            <template #cell(tags)="row">
                <span v-for="(tag, index) in row.value" :key="index" class="mr-1">
                    <b-badge variant="primary">{{ tag }}</b-badge>
                </span>
            </template>
        </b-table>
        <b-button variant="primary" @click="exportToCsv()">Export to CSV</b-button>
    </div>
</template>

<script>
import { getLog } from '@/services/log'
let log = getLog('export-csv');
import { saveAs } from 'file-saver'

export default {
    name: 'PopularMaps',
    props: {
        maps: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'author', label: 'Author' },
                { key: 'playerCount', label: 'Player Count' },
                { key: 'tags', label: 'Tags' }
            ],
        }
    },
    methods: {
        exportToCsv() {
            log.debug('exportToCsv');
            // Generate csv lines and save them with a blob using saveAs
            const csvLines = this.maps.map(map => {
                const tags = map.tags.join(',')
                return `${map.name},${map.author},${map.playerCount},${tags}\n`
            })
            const headline = 'Name,Author,Player Count,Tags\n';
            const blob = new Blob([headline, ...csvLines], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, 'maps.csv');
        }
    }
}
</script>

<style scoped></style>


